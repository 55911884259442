import dataFetchService from "@/services/dataFetchService"
import TipsEmployee from "@/types/Order/TipsEmployee"
import TipsParams from "@/types/TipsParams"
import axios from "axios"

export default {
    async getUrlByOrder(id: string) {
        return await dataFetchService.getItem<Record<'url', string>>(`/api/v1/tips/order/${id}/url`)
    },
    async getUrlByTable(slug: string) {
        return await dataFetchService.getItem<Record<'url', string>>(`/api/v1/tips/table/${slug}/url`)
    },
    async getEmployeeByCode(code: string) {
        return await dataFetchService.getItem<Nullable<TipsEmployee>>(`/api/v1/tips/employee/code/${code}`)
    },
    async getUrlByOldRestId(id: string, params?: TipsParams) {
        const url = `/api/v1/tips/rest/${id}/old`
        const response = await axios<Record<'url', string>>({ method: 'get', url: url, params: params })
        return  response.data
    }
}
