import dataFetchService from "@/services/dataFetchService"
import Restaurant from "@/types/Restaurant/Restaurant"
import MenuCategory from "@/types/MenuCategory"

export default {
    async getRestBySlug(slug: string) {
        return await dataFetchService.getItem<Restaurant>(`/api/v1/rest/${slug}`)
    },
    async getRestByTableSlug(slug: string) {
        return await dataFetchService.getItem<Restaurant>(`/api/v1/table/${slug}/rest`)
    },
    async getRestByOldId(oldId: string) {
        return await dataFetchService.getItem<Restaurant>(`/api/v1/rest/${oldId}/old`)
    },
    async getMenuListByRestSlugAndMenuSlug(restSlug: string, menuSlug: string) {
        return await dataFetchService.getList<MenuCategory[]>(`/api/v1/rest/${restSlug}/menu/${menuSlug}`)
    },
}
