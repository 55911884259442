import dataFetchService from "@/services/dataFetchService"
import PaymentParams from "@/types/Request/PaymentParams"
import Order from "@/types/Order/Order"
import PaymentUrl from "@/types/PaymentUrl"
import Payment from "@/types/Payment/Payment"
import OrderWithTable from "@/types/Order/OrderWithTable"

export default {
    async getOrderByTableSlug(slug: string, preCheckId?: string) {
        let url = `/api/v1/table/${slug}`
        if (preCheckId) {
            url += `?pre_check_id=${preCheckId}`
        }
        return await dataFetchService.getItem<OrderWithTable>(url)
    },
    async getOrder(orderId: string) {
        return await dataFetchService.getItem<Order>(`/api/v1/order/${orderId}`)
    },
    async getOrderByExternalId(oldRestId: string, externalId: string, cb?: () => never) {
        return await dataFetchService.getItem<Order>(`/api/v1/order/${oldRestId}/${externalId}/external`, cb)
    },
    async getPaymentUrlForOrder(orderId: string, data: PaymentParams) {
        return await dataFetchService.request<PaymentUrl>(`/api/v1/order/${orderId}/payment`,'post', data)
    },
    async getPaymentUrlForTips(restSlug: string, data: PaymentParams) {
        return await dataFetchService.request<PaymentUrl>(`/api/v1/rest/${restSlug}/tips/payment`,'post', data)
    },
    async getPayment(paymentId: string) {
        return await dataFetchService.getItem<Payment>(`/api/v1/payment/${paymentId}`)
    },
    async payByCard(paymentId: string, token: string, sessionId: string) {
        return await dataFetchService.request<{ redirectUrl: string }>(`/api/v1/payment/${paymentId}/card-pay`, 'post', {
            one_time_token: token,
            session_id: sessionId
        })
    }
}
