export default {
    set: (key: string, value: unknown) => {
        localStorage.setItem(key, JSON.stringify(value))
        return value
    },
    get: (key: string) => {
        const item = localStorage.getItem(key)
        return item !== null ? JSON.parse(item) : null
    },
    remove: (key: string) => {
        return localStorage.removeItem(key)
    }
}
