<template>
    <div
        class="max-w-screen-md mx-auto bg-base text-base safe-h-screen select-none relative"
    >
        <div class="w-full h-screen flex justify-center items-center" v-if="orderStore.isLoading">
            <img
                v-if="restIsLoad && restHasLoaderLogo"
                :src="orderStore.rest.white_label.loader_logo"
                alt="загрузчик"
                class="w-full h-screen absolute"
            >
            <AnimatedLogo
                v-else-if="restIsLoad && !restHasLoaderLogo"
                class="max-w-xs px-10"
            />
            <div
                v-else
                class="w-full h-screen absolute bg-white"
            >
            </div>
        </div>
        <transition name="fade">
            <div class="safe-h-screen bg-inherit">
                <div
                    v-if="!orderStore.isHome && !orderStore.isLoading"
                    class="flex justify-between items-center py-4 px-4 top-0 fixed max-w-screen-md w-full z-10 backdrop-blur"
                >
                    <div class="w-10 h-10">
                        <button v-if="orderStore.isBack" @click="onBack()"
                                class="w-10 h-10 bg-gray-50/50 text-gray-500 border rounded-full flex justify-center items-center">
                            &leftarrow;
                        </button>
                    </div>
                    <div
                        v-if="orderStore.rest['white_label'] && orderStore.rest['white_label']['second_logo']"
                        class="w-32"
                    >
                        <img :src="orderStore.rest['white_label']['second_logo']" :alt="orderStore.rest['name']" class="w-full" />
                    </div>
                    <KleekLogo v-else/>
                    <div class="w-10"></div>
                </div>
                <slot/>
                <transition name="fade">
                    <div
                        v-if="orderStore.isPaySending"
                        class="fixed top-0 left-0 w-screen h-screen bg-white/50 backdrop-blur-sm z-50 flex justify-center items-center">
                        <svg class="animate-spin h-20 w-20" xmlns="http://www.w3.org/2000/svg" fill="none"
                             viewBox="0 0 24 24">
                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                    stroke-width="4"></circle>
                            <path class="opacity-75" fill="currentColor"
                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                    </div>
                </transition>
                <transition name="fade">
                    <div
                        v-if="orderStore.isLocked && !orderStore.isPaid"
                        class="fixed top-0 left-0 w-screen h-screen bg-white/50 z-30 text-center"
                    >
                        <div class="py-6 bg-white text-sm font-bold">
                            <span class="animate-pulse">Заказ оплачивается</span>
                        </div>
                    </div>
                </transition>
            </div>
        </transition>
    </div>
</template>
<script lang="ts" setup>
import KleekLogo from "@/components/KleekLogo.vue"
import { useOrderStore } from "@/stores/order"

import { useRouter, useRoute } from "vue-router"
import AnimatedLogo from "@/components/AnimatedLogo.vue"
import { computed, onMounted } from "vue"

const router = useRouter()
const route = useRoute()

defineProps({
    isSending: Boolean,
    isLoading: Boolean
})

const orderStore = useOrderStore()

const restIsLoad = computed((): boolean => {
    return Object.keys(orderStore.rest).length > 0
})

const restHasLoaderLogo = computed((): boolean => {
    return !!orderStore.rest.white_label?.loader_logo
})

onMounted(async () => {
    await orderStore.loadGlobalSettings()
})

const onBack = () => {
    const orderId = orderStore.orderId ? orderStore.orderId : route.query.o ? route.query.o as string : null
    const tableSlug = orderStore.tableSlug ? orderStore.tableSlug : route.query.t ? route.query.t as string : null
    if (window.history.state.back === null) {
        if (orderId) {
            router.push({ name: 'order_home', params: { rest_slug: orderStore.restSlug, order_id: orderId }  })
        } else if (tableSlug) {
            router.push({ name: 'table_home', params: { table_slug: tableSlug } })
        }
    } else {
        router.back()
    }
}
</script>
