import axios, { AxiosError } from "axios"
import { notify } from "@kyvg/vue3-notification"
import LogicError from "@/errors/LogicError"

export default {
    async getList<T>(url: string, errorCb?: (message: string, e: unknown) => never): Promise<T> {
        const data = await this.request<Record<'items', T>>(
            url,
            'get',
            {},
            errorCb
        )
        return data.items
    },
    async getItem<T>(url: string, errorCb?: (message: string, e: unknown) => never) {
        return await this.request<T>(url, 'get', {}, errorCb)
    },
    async request<T>(
        url: string, method: 'get' | 'post' | 'put' | 'delete' | 'patch' = 'get',
        data = {},
        errorCb = (message: string, e: unknown): never => {
            notify({
                title: 'Что-то пошло не так',
                text: message,
                type: 'error'
            })
            throw e
        }
    ) {
        let result: Nullable<T> = null
        try {
            const response = await axios<T>({ method, url, data })
            result = response.data
        } catch (e) {
            let message = 'unknown error'
            if (e instanceof AxiosError && e.response) {
                message = e.response.data.error
            } else if (e instanceof Error) {
                message = e.message
            }
            errorCb(message, e)
        }
        if (result === null) {
            throw new LogicError('Не верно обработана ошибка')
        }
        return result
    }
}
