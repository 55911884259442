import './assets/main.css'

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueLazyLoad from 'vue3-lazyload'
import * as Sentry from '@sentry/vue'
import Notifications from '@kyvg/vue3-notification'

import App from './App.vue'
import router from './router'

const app = createApp(App)

Sentry.init({
    app,
    dsn: "https://4912f3a31ac24afdb8f7884d47aebd16@o4505517546799104.ingest.sentry.io/4505517605847040",
})

axios.interceptors.response.use(response => response, async (error) => {
    const route = router.currentRoute.value
    switch (error.response.status) {
        case 404:
            await router.push({
                name: 'not_found',
                params: { pathMatch: route.path.substring(1).split('/') },
                query: route.query,
                hash: route.hash,
            })
            return error.response
        default:
            throw error
    }
})


app.use(createPinia())
app.use(router)
app.use(VueAxios, axios)
app.use(VueLazyLoad, {})
app.use(Notifications)
app.provide('axios', app.config.globalProperties.axios)
app.mount('#app')
