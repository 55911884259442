export default [
    {
        path: '/~:table_slug',
        name: 'table_home',
        props: { orderType: 'table' },
        component: () => import('@/views/HomeView.vue')
    },
    {
        path: '/~:table_slug/order',
        name: 'table_order',
        component: () => import('@/views/EmptyTableView.vue')
    },
    {
        path: '/:rest_slug/:order_id',
        name: 'order_home',
        props: { orderType: 'order' },
        component: () => import('@/views/HomeView.vue')
    },
    {
        path: '/:rest_slug/:order_id/order',
        name: 'order',
        component: () => import('@/views/OrderView.vue')
    },
    {
        path: '/order/:old_rest_id/:external_order_id',
        name: 'external_order',
        props: { orderType: 'external_order' },
        component: () => import('@/views/HomeView.vue')
    },
    {
        path: '/',
        name: 'tips',
        component: () => import('@/views/TipsView.vue')
    },
    {
        path: '/p/:payment',
        name: 'payment',
        component: () => import('@/views/PaymentView.vue')
    },
    {
        path: '/p/success',
        name: 'payment_success',
        component: () => import('@/views/PaymentSuccessView.vue')
    },
    {
        path: '/:rest/menu/:menu',
        name: 'menu',
        component: () => import('@/views/MenuView.vue')
    },
    {
        path: '/p/:payment/card-form',
        name: 'card-form',
        component: () => import('@/views/CardFormView.vue')
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'not_found',
        component: () => import('@/views/PageNotFoundView.vue')
    }
]
